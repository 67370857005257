import React from 'react';

/* js, scss, assets imports */
import './post-list.scss';
import Button from "../../shared/button/button";
import defaultPostImg from '../../../images/blog/post-image.png';
import { Link } from 'gatsby';


class PostList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: 6,
            loadMoreLabel: 'Cargar más',
            postList: []
        }

        this.loadMore = this.loadMore.bind(this);
    }

    loadMore() {
        this.setState((prev) => {
            return { visible: prev.visible + 3 };
        });
    }

    componentDidMount() {
        this.setState({
            postList: this.props.data.allWordpressPost.edges
        })
    }

    render() {
        return (
            <section className="post-list-container">
                <ul className="post-list-ul">
                    {
                        this.state.postList.slice(0, this.state.visible).map(_post => {
                            let post = _post.node;
                            let postImg = post.featured_media;
                            // Check postImg to assign default in case needed. Change to default props? default props nested object?
                            if (postImg == null) {
                                postImg = defaultPostImg;
                            } else {
                                postImg = post.featured_media.source_url;
                            }
                            
                            return (
                                <li className="post-list-element-container" key={post.id}>
                                    <Link to={`/blog/${post.slug}`} className="post-list-element-link">
                                        <picture>
                                            <img className="post-list-element-img" alt={''} src={postImg}></img>
                                        </picture>
                                        <div className="post-list-element-title-container">
                                            <h3 className="post-list-element-title">{post.title}</h3>
                                        </div>
                                        <div className="post-list-element-meta-container">
                                            <p className="post-list-element-meta-author">{post.author.name}</p>
                                            <p className="post-list-element-meta-date">{post.date}</p>
                                        </div>
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
                {
                    this.state.visible < this.state.postList.length &&
                    <Button clickHandler={this.loadMore} type="B" classNameProps="post-list-load-button" title={this.state.loadMoreLabel} />
                }
            </section>
        )
    }
}

export default PostList;