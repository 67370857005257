import React from 'react'
import PropTypes from 'prop-types'
import Button from "../button/button"
import { Waypoint } from 'react-waypoint';
import anime from 'animejs/lib/anime.es.js';
import "./contact-briefly.scss"

function ContactBriefly(props) {

    function scrollIn() {
        const animElements = anime.timeline();
        animElements
            .add({
                targets: '.contact-briefly-desc',
                scale: [0, 1],
                delay: 500,
                duration: 250,
                direction: 'reverse',
                easing: 'linear'
            })
            .add({
                targets: '.contact-briefly-btn',
                opacity: [0, 1],
                direction: 'reverse',
                duration: 250,
                easing: 'linear'
            });
    }

    function scrollOut() {
    }

    return (
        <Waypoint onEnter={scrollIn} onLeave={scrollOut}>
            <div className="contact-briefly-container">
                <h3 className="contact-briefly-desc">{props.description}</h3>
                <Button classNameProps="contact-briefly-btn" type="A" title={props.contactUsLabel} link={props.contactUsAction} />
            </div>
        </Waypoint>
    )
}

ContactBriefly.propTypes = {
    description: PropTypes.string,
    contactUsLabel: PropTypes.string,
    contactUsAction: PropTypes.string
}

ContactBriefly.defaultProps = {
    description: ``,
    contactUsLabel: ``,
    contactUsAction: ``
}

export default ContactBriefly

