import React from "react"
import { graphql, StaticQuery } from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import MainBannerWithImg from "../components/shared/main-banner-with-img/main-banner-with-img"
import ContactBriefly from "../components/shared/contact-briefly/contact-briefly"
import PostList from "../components/blog/post-list/post-list"


const BlogPage = () => (
	<Layout>
		<SEO title="Blog" description="Descubre más acerca de lo que hacemos en SphereDevs. Informate sobre lo último en tendencias y tecnología." lang="es" path="blog/" meta={[
                {
					property: `og:type`,
					content: `website`,
                },
                {
                    property: `og:image`,
                    content: 'https://cms.spheredevs.com/wp-content/uploads/2020/09/Blog-Page-Preview-Image_COMPRESSED.jpg'
                },
                {
                    property: `og:locale`,
                    content: 'es_CR',
                },
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:title`,
					content: "Blog",
				},
				{
					name: `twitter:description`,
					content: "Descubre más acerca de lo que hacemos en SphereDevs. Informate sobre lo último en tendencias y tecnología.",
                },
                {
                    name: `twitter:creator`,
                    content: `@SphereDevs`
                },
                {
                    name: `twitter:site`,
                    content: `@SphereDevs`,
                }
            ]}/>
		<MainBannerWithImg description="Descubre más acerca de lo que hacemos en SphereDevs. Informate sobre lo último en tendencias y tecnología."> </MainBannerWithImg>
        <StaticQuery
                query={
                    graphql`
                        {
                            allWordpressPost {
                                edges {
                                    node {
                                        id
                                        title
                                        content
                                        author {
                                            name
                                        }
                                        date(formatString: "DD MMMM YYYY",locale: "es")
                                        slug
                                        featured_media {
                                            source_url
                                          }
                                    }
                                }
                            }
                        }
                    `
                }

                render={
                    props => (
                        <PostList data={props}></PostList>
                    )
                }
            />
        
		<ContactBriefly description="¿Tienes algo en mente? No esperes más!" contactUsAction="/contact" contactUsLabel="Iniciar proyecto"></ContactBriefly>
    </Layout>
)

export default BlogPage
